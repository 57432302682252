import React from 'react';

import logo from '../../images/logo.png';

import logoicon from '../../images/cashjuiceicon192px-1.png';

import $script from 'scriptjs';

import ReduxConnect from 'Demora/redux-connect';

import { withRouter } from 'react-router-dom';

class TopNavBarLogo extends React.Component {
	componentDidMount() {
		$script(
			'https://www.googletagmanager.com/gtag/js?id=UA-125413086-1',
			function () {
				window.dataLayer = window.dataLayer || [];
				window.gtag = function () {
					dataLayer.push(arguments);
				};

				window.gtag('js', new Date());

				window.gtag('config', 'UA-125413086-1', {
					cookie_flags: 'max-age=7200;secure;samesite=none',
				});
			}
		);
	}

	getUri(l) {
		return l.pathname + (l.search ? l.search : '');
	}

	componentDidUpdate(prevProps) {
		let l = this.props.location;

		let pl = prevProps.location;

		let url = this.getUri(l);

		let purl = this.getUri(pl);

		if (window.gtag && url !== purl) {
			window.gtag('config', 'UA-125413086-1', {
				page_title: document.title,
				page_path: url,
			});
		}
	}

	render() {
		if (document.location.hostname === '2-cj.com') {
			return null;
		}

		return (
			<a href='/' className='navbar-brand' style={{ marginRight: '0px' }}>
				<img className='d-xss-none d-sm-none' src={logoicon} />
				<img className='d-none d-sm-block' src={logo} />
			</a>
		);
	}
}

export default withRouter(
	ReduxConnect(TopNavBarLogo, {
		location: 'location',
		user: 'user',
	})
);
