import extend from 'Demora/extend';

export default {
	feels: (state = {}, action) => {
		switch (action.type) {
			case 'STORE_FEELS':
				extend(state, action.data);

				return Object.assign({}, state);

			default:
				return state;
		}
	},

	feed: (state = {}, action) => {
		switch (action.type) {
			case 'STORE_FEED':
				if (!action.data.feed) {
					return state;
				}

				extend(state, action.data.feed);

				return Object.assign({}, state);

			case 'UPDATE_FEED':
				let feed = action.data.feed;

				Object.keys(feed).map((obj) => {
					if (state[obj]) {
						let posts = Object.keys(feed[obj].p);

						posts.map((post) => {
							if (state[obj].p[post]) {
								let comments = feed[obj].p[post].c
									? Object.keys(feed[obj].p[post].c.p)
									: [];

								if (comments.length == 0) {
									extend(state, feed);
								} else {
									comments.map((cobj) => {
										if (state[obj].p[post].c.p[cobj]) {
											extend(
												state[obj].p[post].c.p[cobj],
												feed[obj].p[post].c.p[cobj]
											);
										}
									});
								}
							}
						});
					}
				});

				return Object.assign({}, state);

			default:
				return state;
		}
	},
};
