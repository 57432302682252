import MessageRoutes from '../../../../vendor/z5internet/react-messages/src/resources/assets/js/src/app/Auth/Routes';

import SocialRoutes from '../../../../vendor/z5internet/react-social/src/resources/assets/js/src/app/Auth/Routes';

import asyncComponent from 'Demora/AsyncComponent';

import OAuthRoutes from '../../../../vendor/z5internet/ruf_oauth/src/resources/assets/js/src/app/Auth/Routes';

import z5StatsRoutes from '../../../../vendor/z5internet/z5-stats/src/resources/assets/js/Auth/Routes';

const AuthHome2 = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-authHome" */ '../../js/components/acctHome/newIndex'
		)
);

const Settings = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-settings" */ '../../../../vendor/z5internet/demora/src/resources/assets/js/src/app/Auth/Settings/Settings'
		)
);

const plan = asyncComponent(
	() =>
		import(/* webpackChunkName: "prot-auth-plan" */ '../../js/components/plan/')
);

const upgrade = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-upgrade" */ '../../js/components/upgrade/'
		)
);

const Issues = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-upgrade" */ '../../js/components/Issues/'
		)
);

const ShowIssue = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-upgrade" */ '../../js/components/Issues/ShowIssue'
		)
);

const Relaunch = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "relaunch" */ '../../js/components/plan/relaunch/relaunch'
		)
);

// let splash_pages = asyncComponent(() => import(/* webpackChunkName: "prot-auth-upgrade" */ '../../js/components/splash_pages/splash_pages'));

export default OAuthRoutes.concat(
	z5StatsRoutes.concat(
		SocialRoutes.concat(
			MessageRoutes.concat([
				{
					path: '/plan/:tab?/:setup_page?',
					component: plan,
				},
				{
					path: '/getStartedFirsts',
					component: asyncComponent(
						() =>
							import(
								/* webpackChunkName: "prot-auth-Firsts" */ '../../js/components/getStarted/Firsts'
							)
					),
				},
				{
					path: '/home/:tab?/:tab1?/:tab2?',
					component: AuthHome2,
				},
				{
					path: '/getStarted/questions',
					component: asyncComponent(
						() =>
							import(
								/* webpackChunkName: "prot-auth-getStartedQ" */ '../../js/components/getStarted/GetStartedQuestions'
							)
					),
				},

				/** Upgrade **/

				{
					path: '/upgrade',
					component: upgrade,
				},
				{
					path: '/upgrade/pro',
					component: asyncComponent(
						() =>
							import(
								/* webpackChunkName: "prot-auth-upg2" */ '../../js/components/upgrade/pay/Upg2'
							)
					),
				},
				{
					path: '/issues',
					component: Issues,
				},
				{
					path: '/issues/:issue',
					component: ShowIssue,
				},
				{
					path: '/upgrade/coaching',
					component: upgrade,
				},
				{
					path: '/upgrade/auto_message',
					component: upgrade,
				},
				{
					path: '/join/return.php',
					component: upgrade,
				},

				/** Settings **/

				{
					path: '/settings/editProfile',
					component: Settings,
				},
				{
					path: '/settings/emailSettings',
					component: Settings,
				},
				{
					path: '/settings/blockedMembers',
					component: Settings,
				},
				{
					path: '/suggestion',
					component: asyncComponent(
						() =>
							import(
								/* webpackChunkName: "prot-auth-suggestion" */ '../../js/components/suggestion'
							)
					),
				},
				{
					path: '/relaunch',
					component: Relaunch,
				},
				//	{
				//		path: '/splash_pages',
				//		component: splash_pages,
				//	},
			])
		)
	)
);
