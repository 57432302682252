import asyncComponent from 'Demora/AsyncComponent';

let m = asyncComponent(
	() =>
		import(/* webpackChunkName: "prot-auth-messages" */ './Messages/Messages')
);

export default [
	{
		path: '/messages',
		component: m,
	},
	{
		path: '/messages/:messageThread',
		component: m,
	},
];
