import Http from 'Demora/http';

let socialHttp = function (method, url, data, fetchConfig, formData) {
	return Http[method](url, data, {}, formData).then((data) => {
		window.reduxStore.dispatch({
			type: 'STORE_FEED',
			data: data,
		});

		return data;
	});
};

export default socialHttp;
