const isUpgraded = function () {
	const user = window.reduxStore.getState().user;

	if (!user.currentTeam || !user.multiAccounts) {
		return 0;
	}

	const currentTeam = user.currentTeam.id;

	const subscribed = user.multiAccounts[currentTeam].subscribed;

	if (subscribed.groups.Pro) {
		return 2;
	}

	for (const i in subscribed.products) {
		if (subscribed.products[i].product_id == 'cashjuice_1') {
			return 1;
		}
	}

	return 0;
};

export default isUpgraded;
