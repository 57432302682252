export default {
	queuedMessages: (state = [], action) => {
		let index = action.index;

		switch (action.type) {
			case 'ADD_QUEUED_MESSAGE':
				state.push(action.message);

				return state.slice(0);

			case 'REMOVE_QUEUED_MESSAGE':
				state = state.slice(0, index).concat(state.slice(index + 1));

				return state.slice(0);

			case 'PUT_QUEUED_MESSAGE_IN_PROCESSING':
				state[index].processing = true;

				return state.slice(0);

			case 'REMOVE_QUEUED_MESSAGE_FROM_PROCESSING':
				state[index].processing = false;

				return state.slice(0);

			default:
				return state;
		}
	},
};
