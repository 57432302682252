import asyncComponent from 'Demora/AsyncComponent';

const statsHome = asyncComponent(
	() => import(/* webpackChunkName: "prot-auth-profile" */ './statsHome')
);

export default [
	{
		path: '/z5-stats',
		component: statsHome,
	},
];
