import React from 'react';

import { withRouter } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

class TrackerComp extends React.Component {
	componentDidMount() {
		let props = this.props;

		window.trackerId = props.id;

		window._paq = [];

		new Promise((resolve, reject) => {
			if (window.URL && typeof URL === 'function') {
				resolve(undefined);
			} else {
				import(/* webpackChunkName: "URLPolyfill" */ 'url-polyfill').then(
					(d) => {
						resolve(undefined);
					}
				);
			}
		}).then((a) => {
			this.recordHit();

			if (props.user.id) {
				window._paq.push(['setUserId', props.user.id + '']);
			}

			import(/* webpackChunkName: "homeTracker" */ './Tracker');
		});
	}

	componentDidUpdate(prevProps) {
		let props = this.props;
		if (prevProps.user.id !== props.user.id) {
			window._paq.push(['setUserId', props.user.id + '']);
		}
		if (prevProps.location !== props.location) {
			this.recordHit();
		}
	}

	recordHit() {
		window._paq.push(['setCustomUrl', this.getURL()]);
		window._paq.push(['trackPageView']);
	}

	getURL() {
		let props = this.props;
		let url;
		if (props.getURL) {
			url = props.getURL(props);
		} else {
			url = document.URL;
		}

		return this.removeParams(url);
	}

	removeParams(url) {
		let loc = new URL(url);

		const params = new URLSearchParams(loc.search);

		if (loc.pathname.match(/^\/login/)) {
			params.delete('redi');
		}

		loc.search = params.toString();

		return loc.toString();
	}

	render() {
		return null;
	}
}

export default withRouter(
	ReduxConnect(TrackerComp, {
		user: 'user',
	})
);
