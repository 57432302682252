import React from 'react';

import socialHttp from '../../../../vendor/z5internet/react-social/src/resources/assets/js/src/utils/socialHttp';

import throttle from 'lodash/throttle';

class RecordFeedActivity extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.startPos = {}; // 0 above 1 below

		this.timeInView = {};
		this.seen = {};
	}

	componentDidMount() {
		window.addEventListener('scroll', throttle(this.handleScroll, 500));
		window.addEventListener('resize', throttle(this.handleScroll, 500));

		document.addEventListener(
			'react_social_click_on_post_link',
			this.handleClickOnLink
		);
		document.addEventListener(
			'react_social_view_profile',
			this.handleViewOnProfile
		);
	}

	handleClickOnLink = (e) => {
		let pid = e.detail.pid;
		let url = e.detail.url;

		this.sendEvent(pid, 'post_link_click', { url: url });
	};

	handleViewOnProfile = (e) => {
		let obj = e.detail.obj;

		this.sendEvent(obj, 'profile_view', {});

		this.handleScroll();
	};

	handleScroll = () => {
		let items = Array.from(document.querySelectorAll('[data-pid]'));

		for (var i = 0; i < items.length; i++) {
			let item = items[i];

			this.hasItemBeenViewed(item);
		}
	};

	hasItemBeenViewed = (el) => {
		if (el.clientHeight == 0) {
			return;
		}

		let pid = el.dataset.pid;

		const scroll = window.scrollY || window.pageYOffset;
		const boundsTop = el.getBoundingClientRect().top + scroll;

		if (!this.seen[pid]) {
			this.seen[pid] = 0;
		}

		if (!this.timeInView[pid]) {
			this.timeInView[pid] = false;
		}

		if (this.startPos[pid] === undefined) {
			this.startPos[pid] = 1;
		}

		let tv = scroll;

		let bv = scroll + window.innerHeight;

		let ti = boundsTop;

		let bi = boundsTop + el.clientHeight;

		let posti = (function () {
			if (ti <= tv) {
				return 0;
			}

			if (tv < ti && ti < bv) {
				return 1;
			}

			if (bv <= ti) {
				return 2;
			}
		})();

		let posbi = (function () {
			if (bi <= tv) {
				return 0;
			}

			if (tv < bi && bi < bv) {
				return 1;
			}

			if (bv <= bi) {
				return 2;
			}
		})();

		let seen = 0;

		let iv;
		let outOfView = false;

		if (posti == 0 && posbi == 0) {
			iv = 0;
			outOfView = true;
		}

		if (posti == 2 && posbi == 2) {
			iv = 1;
			outOfView = true;
		}

		// post is still out of view
		// OR post started from 1 position and went out of view same side
		// OR post has been seen and is now out of view

		if (iv === this.startPos[pid] || (this.seen[pid] == 10 && outOfView)) {
			this.seen[pid] = 0;
			this.startPos[pid] = iv;
			this.timeInView[pid] = false;

			return;
		}

		switch (this.startPos[pid]) {
			case 0:
				if (posbi == 1 || posbi == 2) {
					seen = 5;

					if (posti == 1 || posti == 2) {
						seen = 10;
					}

					if (posti == 2) {
						this.startPos[pid] = 1;
					}
				}

				break;
			case 1:
				if (posti == 0 || posti == 1) {
					seen = 5;

					if (posbi == 0 || posbi == 1) {
						seen = 10;
					}
				}

				if (posbi == 0) {
					this.startPos[pid] = 0;
				}

				break;
		}

		if (!seen) {
			this.seen[pid] = 0;
			return;
		}

		if (seen != this.seen[pid] && this.seen[pid] < 10) {
			if (this.seen[pid] == 0) {
				this.timeInView[pid] = new Date().getTime();
				this.seen[pid] = 5;
			}

			if (seen == 10) {
				if (
					!this.timeInView[pid] ||
					new Date().getTime() - this.timeInView[pid] < 2000
				) {
					if (outOfView) {
						this.seen[pid] = 0;
						this.timeInView[pid] = false;
					}

					return;
				}
			}

			this.seen[pid] = seen;

			this.sendEvent(pid, 'post_view', {
				seen: seen,
				time: seen == 10 && new Date().getTime() - this.timeInView[pid],
			});
		}
	};

	sendEvent(obj, type, notes) {
		notes.source = 'website';

		socialHttp('post', '/data/feed/recordFeedActivity/' + type, {
			notes: notes,
			obj: obj,
		});
	}

	render() {
		return null;
	}
}

export default RecordFeedActivity;
