import React from 'react';
import { Link } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

class FooterNavBar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			collapsed: true,
			user: props.user.id,
		};
	}

	componentDidUpdate() {
		let props = this.props;

		if (props.user.id && !this.state.user) {
			this.setState({
				user: props.user.id,
			});
		}
	}

	render() {
		let social = null;

		const cols = 'col-3';

		if (this.props.user.id) {
			social = (
				<div className='text-white col-12' style={{ whiteSpace: 'nowrap' }}>
					<a
						href='https://www.facebook.com/realCashJuice'
						className='text-white d-inline-block mr-4'
						target='_blank'
						rel='nofollow'
					>
						<i className='fab fa-facebook-f'></i>
					</a>
					<a
						href='https://twitter.com/realcashjuice'
						className='text-white d-inline-block mr-4'
						target='_blank'
						rel='nofollow'
					>
						<i className='fab fa-twitter'></i>
					</a>
					<a
						href='https://www.youtube.com/channel/UCJs4slTfvpDpsAMLYOW10vQ'
						className='text-white d-inline-block'
						target='_blank'
						rel='nofollow'
					>
						<i className='fab fa-youtube'></i>
					</a>
				</div>
			);
		}

		return (
			<div style={{ flexGrow: 1 }}>
				<div className='d-flex'>
					<div className='row text-center mx-auto'>
						<div className={cols}>
							<Link to='/contact' className='text-white' rel='nofollow'>
								Contact
							</Link>
						</div>
						<div className={cols}>
							<Link to='/terms' className='text-white' rel='nofollow'>
								Terms
							</Link>
						</div>
						<div className={cols}>
							<Link to='/privacy' className='text-white' rel='nofollow'>
								Privacy
							</Link>
						</div>
						<div className={cols}>
							<Link to='/blog' className='text-white'>
								Blog
							</Link>
						</div>
						{social}
					</div>
				</div>
				<div className='text-center mx-auto'>
					<small style={{ color: '#ffffff' }}>
						Copyright &copy; Z5 Internet Ltd 2009-{new Date().getFullYear()}
					</small>
				</div>
			</div>
		);
	}
}

export default ReduxConnect(FooterNavBar, {
	user: 'user',
});
