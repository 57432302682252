import React from 'react';

import logo from '../../images/logo.png';

import { Link, withRouter } from 'react-router-dom';

import classNames from 'classnames';

import ReduxConnect from 'Demora/redux-connect';

import isUpgradedFunction from '../../js/components/upgrade/pay/isUpgraded';

import RecordFeedActivity from '../../js/utils/recordFeedActivity';

import config from '../config';

import asyncComponent from 'Demora/AsyncComponent';

import TrackerComp from './TrackerComp';

import { Scrollbars } from 'react-custom-scrollbars-2';

import z5Stats from '../../../../vendor/z5internet/z5-stats/src/resources/assets/js/stats';

import * as Sentry from '@sentry/react';

const ReactBurgerMenu = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "react-burger-menu" */ 'react-burger-menu/lib/menus/slide'
		)
);

class PreHeader extends React.Component {
	constructor(props) {
		super();

		this.state = {
			menuOpen: false,
			navigation: null,
			loaded: null,
		};

		if (
			document.location.hostname == '2-cj.com' &&
			!document.location.pathname.match(/viewPlan/) &&
			document.location.pathname !== '/'
		) {
			document.location = 'https://cashjuice.com' + props.location.pathname;
		}
	}

	componentDidUpdate(prevProps) {
		const user = this.props.user;
		if (user.id !== prevProps.user.id) {
			this.navigation = this.getNavigation();

			this.loadUserModules();
			this.z5Stats.setUserID(user.id);

			this.setSentryUser(user);
		}
	}

	componentDidMount() {
		const user = this.props.user;
		this.navigation = this.getNavigation();

		if (user.id) {
			this.loadUserModules();
		}

		this.z5Stats = new z5Stats(user.id);

		this.props.history.listen((location, action) => {
			this.z5Stats.changePage();
		});

		this.setSentryUser(user);

		if (document.location.hostname === '2-cj.com') {
			const elements = [
				document.querySelectorAll('.navbarTop .nav'),
				document.querySelectorAll('.navbarBottom > div'),
			];

			for (const elements1 of elements) {
				for (const elem of elements1) {
					elem.classList.add('d-none');
				}
			}
		}
	}

	setSentryUser = (user) => {
		Sentry.setUser({
			id: user.id,
			username: user.first_name,
			email: user.email,
		});
	};

	loadUserModules() {
		this.setState({
			loaded: true,
		});
	}

	getNavigation() {
		const user = this.props.user;

		const upg = isUpgradedFunction();

		if (!this.props.user.id) {
			return;
		}

		let navigation = [
			{
				url: '/',
				icon: 'fa fa-home',
				name: 'Home',
			},
			{
				url: '/home',
				icon: 'fas fa-tachometer-alt',
				name: 'Dashboard',
			},
			{
				heading: 'Account settings',
			},
			{
				url: '/settings',
				icon: 'fa fa-cogs',
				name: 'Settings',
			},
			{
				url: '/settings/security',
				icon: 'fas fa-shield-alt',
				name: 'Change password',
			},
			{
				heading: 'The Plan',
			},
			{
				url: '/plan/',
				icon: 'far fa-file-alt',
				name: 'The Plan',
				color: '#ac0101',
			},
			{
				url: '/plan/setup',
				icon: 'fa fa-cog',
				name: 'Setup',
				dontShowInIconList: true,
			},
			{
				heading: 'Upgrade',
			},
		];

		if (upg < 2) {
			navigation = navigation.concat([
				{
					url: '/upgrade',
					icon: 'fa fa-unlock',
					name: 'Upgrade',
					color: '#ac0101',
				},
			]);
		}

		if (upg > 1) {
			navigation = navigation.concat([
				/*
				{
					url: '/upgrade/coaching',
					icon: 'far fa-futbol',
					name: 'Coaching',
				},
*/
				{
					url: '/upgrade/auto_message',
					icon: 'far fa-comment-alt',
					name: 'Auto message',
				},
			]);
		}

		navigation = navigation.concat([
			{
				url: '/home/promote/ads',
				icon: 'fab fa-buysellads',
				name: 'Ads',
				color: '#ac0101',
			},
			{
				heading: 'Stats',
			},
			{
				url: '/home/promote/planStats',
				icon: 'far fa-chart-bar',
				name: 'Your stats',
			},
			{
				heading: 'Commission',
			},
			{
				url: '/home/earn/commission',
				icon: 'fas fa-dollar-sign',
				name: 'Commission',
			},
			{
				heading: 'Community',
			},
			{
				url: '/home/earn/referrals',
				icon: 'fa fa-users',
				name: 'Referrals',
			},
			{
				url: '/profile',
				icon: 'fa fa-user',
				name: 'Profile',
			},
			{
				url: '/home/socialize/feed',
				icon: 'fa fa-rss',
				name: 'Feed',
			},
			{
				url: '/home/socialize/bookmarkedPosts',
				icon: 'fas fa-bookmark',
				name: 'Bookmarked Posts',
			},
			{
				url: '/home/socialize/followers',
				icon: 'fa fa-users',
				name: 'Followers',
			},
			{
				url: '/messages',
				icon: 'far fa-comment-alt',
				name: 'Messages',
			},
			{
				url: '/settings/profile',
				icon: 'far fa-image',
				name: 'Profile picture',
			},
			{
				url: '/home/socialize/leaguePositions',
				icon: 'fa fa-arrow-up',
				name: 'Leagues',
			},
			{
				url: '/blog',
				icon: 'fas fa-rss',
				name: 'Blog',
			},
			{
				url: '/home/socialize/groups',
				icon: 'fa fa-users',
				name: 'Groups',
			},
		]);

		navigation = navigation.concat([
			{
				heading: 'Legal',
			},
			{
				url: '/terms',
				icon: 'fas fa-gavel',
				name: 'Terms',
			},
			{
				url: '/privacy',
				icon: 'fa fa-user-secret',
				name: 'Privacy',
			},
			{
				heading: 'Help',
			},
			{
				url: '/contact',
				icon: 'far fa-envelope',
				name: 'Contact us',
			},
			{
				url: '/suggestion',
				icon: 'fas fa-comment',
				name: 'Make a suggestion',
			},
		]);

		this.setState({
			navigation,
		});
	}

	closeMenu = () => {
		this.setState({
			menuOpen: false,
		});
	};

	menuStateChange = (t) => {
		this.setState({
			menuOpen: t.isOpen,
		});
	};

	getURL(props) {
		const loc = new URL(document.URL);

		const params = new URLSearchParams(loc.search);

		params.delete('socApiToken');

		if (loc.pathname.match(/^\/public_campaign_post/)) {
			params.delete('rid');
			params.delete('ppu');
		}

		loc.search = params.toString();

		return loc.toString();
	}

	render() {
		const props = this.props;

		var styles = {
			bmBurgerButton: {
				position: 'fixed',
				width: '36px',
				height: '30px',
				top: '17px',
				left: '13px',
			},
			bmBurgerBars: {
				background: '#ffffff',
			},
			bmCrossButton: {
				height: '24px',
				width: '24px',
			},
			bmCross: {
				background: '#fff',
			},
			bmMenu: {
				background: '#000048',
			},
			bmItem: {
				color: '#ffffff',
			},
			bmMorphShape: {
				fill: '#000048',
			},
			bmItemList: {
				color: '#000048',
				padding: '0.8em',
			},
			bmOverlay: {
				background: 'rgba(0, 0, 0, 0.3)',
			},
			bmMenuWrap: {
				top: 0,
				left: 0,
				width: '220px',
			},
		};

		let show = true;

		if (
			config.hideHeader(props.location) ||
			!this.state.loaded ||
			!props.user.id ||
			!props.user.finishedGetStarted ||
			location.pathname.match(/^\/getStarted/)
		) {
			show = false;
		}

		return (
			<div>
				<TrackerComp getURL={this.getURL} id='2' />
				{show && (
					<div
						style={{
							zIndex: 2000,
							position: 'fixed',
							top: 0,
						}}
					>
						{!this.state.menuOpen && (
							<div
								id='scrollbar_side_menu'
								className='d-none d-md-flex'
								style={{
									position: 'fixed',
									left: 0,
									flexDirection: 'column',
									width: '70px',
									top: 0,
									height: '100vh',
								}}
							>
								<div
									style={{ height: '70px', flexGrow: 0, flexShrink: 0 }}
								></div>

								<div
									style={{
										maxHeight: 'inherit',
										height: 'inherit',
										flexGrow: 1,
									}}
								>
									<Scrollbars>
										<ul className='text-center p-0'>
											{this.state.navigation.map((n, k) => {
												if (!n.icon || n.dontShowInIconList) {
													return null;
												}

												n.backgroundColor = '';
												n.borderColor = '';

												if (props.location.pathname == n.url) {
													n.backgroundColor = '#e6f3f7';
													n.border = '1px solid #aaaaaa';
												}

												return (
													<li
														key={k}
														style={{
															padding: '10px 5px',
															marginBottom: '5px',
															lineHeight: 1,
															backgroundColor: n.backgroundColor,
															border: n.border,
														}}
													>
														<Link
															to={n.url}
															style={{
																fontSize: '10px',
																color: n.color ? n.color : '#000048',
															}}
														>
															<i
																style={{
																	fontSize: '25px',
																	color: n.color ? n.color : '#777',
																	width: '100%',
																	marginBottom: '2px',
																}}
																className={classNames('fa-fw', n.icon)}
															></i>
															{n.name}
														</Link>
													</li>
												);
											})}

											<li style={{ paddingBottom: '108px' }} />
										</ul>
									</Scrollbars>
								</div>

								<div
									className='text-center'
									style={{
										height: '80px',
										flexGrow: 0,
										flexShrink: 0,
										padding: '10px 5px',
										marginBottom: '5px',
										lineHeight: 1,
									}}
								>
									<Link
										to='/plan'
										style={{ fontSize: '10px', color: '#000048' }}
									>
										<i
											style={{
												fontSize: '25px',
												width: '60px',
												marginBottom: '2px',
											}}
											className={classNames('far fa-fw', 'fa-file-alt')}
										></i>
										The Plan
									</Link>
								</div>
							</div>
						)}
						{this.state.navigation && props.user.id && (
							<Scrollbars>
								<ReactBurgerMenu
									id='joyride_menu_button'
									outerContainerId={'appRoot'}
									pageWrapId={'pageContent'}
									styles={styles}
									isOpen={this.state.menuOpen}
									onStateChange={this.menuStateChange}
								>
									<div className='text-center'>
										<img src={logo} style={{ padding: '20px 0' }} />
									</div>

									{this.state.navigation.map((n, k) => {
										if (n.heading) {
											return (
												<div
													style={{
														margin: '16px 0 8px',
														borderTop: '1px solid #777',
														paddingTop: '16px',
													}}
													key={k}
													className='menu-item'
												>
													{n.heading}
												</div>
											);
										}

										return (
											<Link
												id={n.name}
												key={k}
												className='menu-item'
												to={n.url}
												onClick={this.closeMenu}
											>
												<i
													style={{ paddingLeft: '17px', color: '#777' }}
													className={classNames('fa-fw', n.icon)}
												></i>
												<span style={{ paddingLeft: '20px' }}>{n.name}</span>
											</Link>
										);
									})}

									<a
										id='logout'
										className='menu-item'
										style={{ color: '#ffffff' }}
										href='/logout'
									>
										<i
											style={{ paddingLeft: '17px', color: '#777' }}
											className='fas fa-fw fa-btn fa-sign-out-alt'
										/>
										<span style={{ paddingLeft: '20px' }}>Logout</span>
									</a>

									<div className='pt-5'></div>
								</ReactBurgerMenu>
							</Scrollbars>
						)}
						<RecordFeedActivity />
					</div>
				)}
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(PreHeader, {
		user: 'user',
	})
);
